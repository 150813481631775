/**
 * @generated SignedSource<<dcf14a5bfe600f4f255ff3cae9fe36cd>>
 * @relayHash 80d70ebffd722a288ac6395574dee207
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/504.0.0-2025-04-03T17:15:09.792Z/SellerReviewsModalQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerReviewsModalQuery$variables = {
  isMobileScrollContainer: boolean;
  orderByHighest: boolean;
  orderByLowest: boolean;
  orderByNewest: boolean;
  page: number;
  sellerId: string;
};
export type SellerReviewsModalQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"SellerReviewsMobileModalContent_viewer" | "SellerReviewsModalContent_viewer">;
  };
};
export type SellerReviewsModalQuery = {
  response: SellerReviewsModalQuery$data;
  variables: SellerReviewsModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isMobileScrollContainer"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderByHighest"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderByLowest"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderByNewest"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sellerId"
},
v6 = {
  "alias": "feedbackFormPhotoUpload",
  "args": [
    {
      "kind": "Literal",
      "name": "feature",
      "value": "feedbackFormPhotoUpload"
    }
  ],
  "kind": "ScalarField",
  "name": "featureFlag",
  "storageKey": "featureFlag(feature:\"feedbackFormPhotoUpload\")"
},
v7 = {
  "kind": "Literal",
  "name": "excludeInAppealProcess",
  "value": true
},
v8 = {
  "kind": "Literal",
  "name": "excludePrivateItemReviews",
  "value": true
},
v9 = {
  "kind": "Variable",
  "name": "sellerId",
  "variableName": "sellerId"
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderFeedbackMultipleQueryMetadata",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AggregatedOrderFeedbackType",
      "kind": "LinkedField",
      "name": "aggregatedFeedback",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AggregatedOrderFeedbackQuestionType",
          "kind": "LinkedField",
          "name": "overallExperience",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mean",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfPages",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Item",
  "kind": "LinkedField",
  "name": "item",
  "plural": false,
  "selections": [
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contemporaryTrackingString",
      "storageKey": null
    },
    (v13/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "size",
          "value": "thumb"
        }
      ],
      "kind": "ScalarField",
      "name": "firstPhotoWebPath",
      "storageKey": "firstPhotoWebPath(size:\"thumb\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        (v12/*: any*/),
        (v13/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLinkable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "publicNote",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "language",
          "storageKey": null
        }
      ],
      "type": "StandardQuestion",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freetext",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visible",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedDate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buyerFirstName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderFeedbackPhoto",
  "kind": "LinkedField",
  "name": "orderPhotos",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "smallPath",
      "storageKey": null
    },
    (v13/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "buyerShippingAddress",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayCountry",
      "storageKey": null
    },
    (v13/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "overallExperience",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rating",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalResults",
  "storageKey": null
},
v23 = {
  "kind": "Literal",
  "name": "orderByNewest",
  "value": true
},
v24 = {
  "alias": "reviewsWithPhotos",
  "args": [
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "kind": "Literal",
      "name": "first",
      "value": 0
    },
    {
      "kind": "Literal",
      "name": "hasVisibleFeedbackImages",
      "value": true
    },
    (v23/*: any*/),
    {
      "kind": "Literal",
      "name": "page",
      "value": 0
    },
    (v9/*: any*/)
  ],
  "concreteType": "OrderFeedbackMultipleQueryConnection",
  "kind": "LinkedField",
  "name": "orderFeedbackMultiple",
  "plural": false,
  "selections": [
    (v22/*: any*/)
  ],
  "storageKey": null
},
v25 = [
  (v7/*: any*/),
  (v8/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "orderByHighest",
    "value": false
  },
  {
    "kind": "Literal",
    "name": "orderByLowest",
    "value": false
  },
  (v23/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SellerReviewsModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "isMobileScrollContainer",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SellerReviewsModalContent_viewer"
              }
            ]
          },
          {
            "condition": "isMobileScrollContainer",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SellerReviewsMobileModalContent_viewer"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "SellerReviewsModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "isMobileScrollContainer",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 5
                  },
                  {
                    "kind": "Variable",
                    "name": "orderByHighest",
                    "variableName": "orderByHighest"
                  },
                  {
                    "kind": "Variable",
                    "name": "orderByLowest",
                    "variableName": "orderByLowest"
                  },
                  {
                    "kind": "Variable",
                    "name": "orderByNewest",
                    "variableName": "orderByNewest"
                  },
                  {
                    "kind": "Variable",
                    "name": "page",
                    "variableName": "page"
                  },
                  (v9/*: any*/)
                ],
                "concreteType": "OrderFeedbackMultipleQueryConnection",
                "kind": "LinkedField",
                "name": "orderFeedbackMultiple",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderFeedbackMultipleQueryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderFeedback",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v14/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/)
                ],
                "storageKey": null
              },
              (v24/*: any*/)
            ]
          },
          {
            "condition": "isMobileScrollContainer",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": "paginatedFeedback",
                "args": (v25/*: any*/),
                "concreteType": "OrderFeedbackMultipleQueryConnection",
                "kind": "LinkedField",
                "name": "orderFeedbackMultiple",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderFeedbackMultipleQueryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderFeedback",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v14/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v13/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "paginatedFeedback",
                "args": (v25/*: any*/),
                "filters": [
                  "sellerId",
                  "excludeInAppealProcess",
                  "orderByNewest",
                  "orderByHighest",
                  "orderByLowest",
                  "excludePrivateItemReviews"
                ],
                "handle": "connection",
                "key": "SellerReviewsMobileModalContent_paginatedFeedback",
                "kind": "LinkedHandle",
                "name": "orderFeedbackMultiple"
              },
              (v24/*: any*/)
            ]
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/504.0.0-2025-04-03T17:15:09.792Z/SellerReviewsModalQuery",
    "metadata": {},
    "name": "SellerReviewsModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "30191515264875351a8b376be4ebba28";

export default node;
